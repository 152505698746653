
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./JordyKerwick.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import JordyKerwickPhoto1 from "../../../../res/Photos site/Jordy Kerwick/Jordy Kerwick .jpg"
import JordyKerwickPhoto2 from "../../../../res/Photos site/Jordy Kerwick/Jordy Kerwick studio.jpg"
import JordyKerwickPhoto3 from "../../../../res/Photos site/Jordy Kerwick/Jordy Kerwick-Studio interior_JPG.webp"
import JordyKerwickPhoto4 from "../../../../res/Photos site/Jordy Kerwick/jordy-kerwick-Delphian Gallery.jpg"
import JordyKerwickPhoto5 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Jordy Kerwick - Hydra \" Amelie \" - 2021.jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/kerwick/portrait.jpeg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "JordyKerwick",
  name: "JordyKerwick",
  description:"Jordy Kerwick est un artiste Australien autodidacte né en 1982 à Melbourne, il vit et travaille aujourd’hui à Albi dans le sud de la France. Ses compositions, majoritairement vives et dont la perspective est quasi-inexistante, immortalisent des sujets complètement fantasmés et d'autres beaucoup plus autobiographiques, souvent au sein d'une même toile. Il reprend par ailleurs des thèmes classiques de la peinture, notamment les natures mortes et les vanités. Il y ajoute des éléments du quotidien aux connotations antagonistes comme les fleurs et les cigarettes, juxtaposant ainsi les aspects attrayants et grisâtres de la vie. Son iconographie extrêmement reconnaissable est construite autour d’un ensemble d’éléments graphiques récurrents, tels des motifs, composés de cobras, de têtes de loups, de plumes, de femmes dénudées ou d’éléments végétaux. Les animaux prédateurs sont de plus en plus présents dans ses oeuvres récentes. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de JordyKerwick.",
  photos: [
    { src: JordyKerwickPhoto1, name: "JordyKerwick" },
    { src: JordyKerwickPhoto2, name: "JordyKerwick" },
    { src: JordyKerwickPhoto3, name: "JordyKerwick" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: JordyKerwickPhoto5, name: "Jordy Kerwick", artist:"Jordy Kerwick (né en 1982)", title:"Hydra \"Amelie\", 2021", desc1: "Bronze peint", desc2 :"Painted bronze", desc3: "42 x 35,5 x 25,5 cm (H x L x W)", desc4:"16 1/2 x 14 x 10 in.", desc5:"L'oeuvre fait partie d'une série de 15 serpents, chaque pièce est unique", desc6:"The artwork is a part of a series of 15 snakes, each piece is unique.",desc7:"- Collection particulière.\n- Galerie Streckfuss, Berlin.", desc10: "<h4>Certificat d'authenticité de la Galerie Streckfuss, Berlin, édité en décembre 2021\n<i>Certificate of authenticity of the Streckfuss Gallery, Berlin, edited in December 2021.</i></h4>" },
  ],
};

const JordyKerwick = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>JORDY KERWICK <h2 style={{paddingLeft: "16px"}}>(Australien)</h2></h1>
          <h3 className={NameWrapper} >Born in 1982</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default JordyKerwick;