// extracted by mini-css-extract-plugin
export var ArtistDescription = "JordyKerwick-module--ArtistDescription--cYmq7";
export var ArtistInfos = "JordyKerwick-module--ArtistInfos--QZqX+";
export var ButtonWrapper = "JordyKerwick-module--ButtonWrapper --LZeTI";
export var CardWrapper = "JordyKerwick-module--CardWrapper--Y2sXx";
export var CarrouselWrapper2 = "JordyKerwick-module--CarrouselWrapper2--EuR+0";
export var Citations = "JordyKerwick-module--Citations--TIleu";
export var DescriptionWrapper = "JordyKerwick-module--DescriptionWrapper--Aw2ln";
export var ImageWrapper = "JordyKerwick-module--ImageWrapper--vjfJj";
export var LinkWrapper = "JordyKerwick-module--LinkWrapper--lyz1w";
export var MobileProtrait = "JordyKerwick-module--MobileProtrait--I2dfh";
export var More = "JordyKerwick-module--More--UFZ8j";
export var MoreButton = "JordyKerwick-module--MoreButton--eZJ8P";
export var NameWrapper = "JordyKerwick-module--NameWrapper--AonTn";
export var PdpWrapper = "JordyKerwick-module--PdpWrapper--4VfE7";
export var PhotosWrapper = "JordyKerwick-module--PhotosWrapper--VCHnJ";
export var ProfilWrapper = "JordyKerwick-module--ProfilWrapper--YyjuT";
export var TitleWrapper = "JordyKerwick-module--TitleWrapper--nfkdW";
export var Wrapper = "JordyKerwick-module--Wrapper--zRVkj";